
 <template>
  <div>
    <!-- modal login-->

   <b-modal
      id="modal-login"
      cancel-variant="outline-secondary"
      ok-title="Show data"
      centered
       no-close-on-backdrop
      @ok="Filter()"
      title="Filters"
    >
      <b-form>
        <b-form-group label="Select option">
          <!-- <h5>Select filter</h5> -->
          <v-select
            v-model="filterData"
            :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
            :options="productfilterOptions"
            label="text"
            :clearable="false"
            placeholder="Select Filter"
          />
        </b-form-group>
      </b-form>
    </b-modal>

    <b-modal
      id="modal-select2"
      size="md"
      header-text-variant="primary"
      :title="detailObj.business"
      centered
    >
      <div v-if="loading == true">
        <b-spinner
          variant="primary"
          type="grow"
          style="margin-left: 350px"
          class="p-2"
          label="Spinning"
        ></b-spinner>
      </div>

      <div v-if="loading == false">
        <div>
          <b-container class="bv-example-row">
            <b-card>
              <b-row>
                <div lg="3" md="3" sm="12" cols="12">
                  <!-- class="profile-img pb-1" -->
                  <div>
                    <b-card-img
                      :src="detailObj.cover"
                      alt="Image"
                      class="rounded-1"
                      style="height: 160px; width: 110px"
                    ></b-card-img>
                  </div>
                </div>

                <b-col lg="8" md="8" sm="12" cols="12">
                  <h3>{{ detailObj.name}}</h3>
                  <b-row >
                    <b-col>
                      
                        {{
                          detailObj.category + ' -> ' + detailObj.subcategory
                        }}
                     
                    </b-col>
                  </b-row>
                  <b-row style="margin-top:5px;">
                    <b-col>
                      <b-card-text> AED {{ detailObj.charges }} </b-card-text>
                    </b-col>
                  </b-row>
                     <b-row style="margin-top:5px;" v-if="detailObj.forHome !== 'Salon'">
                    <b-col>
                      <b-card-text>
                        Visiting charges :
                        <span>
                          {{ 'AED '+ detailObj.chargesAtHome }}
                        </span>
                      </b-card-text>
                    </b-col>
                  </b-row>
                  <b-row style="margin-top:5px;">
                    <b-col>
                      <b-card-text>
                        Available at :
                        <span class="text-primary">
                          {{ detailObj.forHome }}
                        </span>
                      </b-card-text>
                    </b-col>
                  </b-row>
                  <b-row style="margin-top: 10px">
                    <b-col>
                      <b-card-text>
                        <b-badge
                          v-if="detailObj.status == 'Approved'"
                          variant="success"
                          class="badge-glow"
                        >
                          Approved
                        </b-badge>

                        <b-badge
                          v-if="detailObj.status == 'Pending'"
                          variant="secondary"
                          class="badge-glow"
                        >
                          Pending
                        </b-badge>
                        <b-badge
                          v-if="detailObj.status == 'Suspended'"
                          variant="warning"
                          class="badge-glow"
                        >
                          Suspended
                        </b-badge>
                        <b-badge
                          v-if="detailObj.status == 'Rejected'"
                          variant="danger"
                          class="badge-glow"
                        >
                          Rejected
                        </b-badge>
                      </b-card-text>
                    </b-col>
                  </b-row>
                </b-col>
              </b-row>
            </b-card>

            <b-card>
              <b-row>
                <b-col cols="12">
                  <h3>Description</h3>
                  <p>{{ detailObj.description }}</p>
                </b-col>
              </b-row>
            </b-card>

            <b-card>
              <b-row>
                <b-col cols="12">
                  <h3 style="margin-left: 5px">Images</h3>
                  <gallery
                    :images="detailObj.images"
                    :index="index"
                    @close="index = null"
                  ></gallery>
                  <b-row>
                    <b-col
                      cols="*"
                      style="margin-inline: 2px"
                      v-for="(image, imageIndex) in detailObj.images"
                      :key="imageIndex"
                    >
                      <b-card-img
                        @click="index = imageIndex"
                        :src="image"
                        alt="Image"
                        class="rounded-1"
                        style="height: 160px; width: 110px"
                      ></b-card-img>
                    </b-col>
                  </b-row>
                </b-col>
              </b-row>
            </b-card>
            <b-row>
              <b-col></b-col>
              <b-col>
                <b-row class="text-center">
                  <b-col>
                    <b-button
                      v-if="
                        detailObj.status === 'Pending' ||
                        detailObj.status === 'Suspended' ||
                        detailObj.status === 'Rejected'
                      "
                      v-ripple.400="'rgba(40, 199, 111, 0.15)'"
                      variant="outline-success"
                      @click="ServiceStatus(detailObj.id, 'Approved')"
                      >Approve</b-button
                    >

                    <b-button
                      v-if="detailObj.status === 'Pending'"
                      v-ripple.400="'rgba(234, 84, 85, 0.15)'"
                      variant="outline-danger"
                      @click="ServiceStatus(detailObj.id, 'Rejected')"
                      >Reject</b-button
                    >
                    <b-button
                      v-if="detailObj.status === 'Approved'"
                      v-ripple.400="'rgba(234, 84, 85, 0.15)'"
                      variant="outline-danger"
                      @click="ServiceStatus(detailObj.id, 'Suspended')"
                      >Suspend</b-button
                    >
                  </b-col>
                </b-row>
              </b-col>
              <b-col></b-col>
            </b-row>
          </b-container>
        </div>
      </div>
    </b-modal>

  
    <div no-body class="mb-2" style="padding: 20px" cols="12" md="4" sm="12">
      <div class="">
        <!-- Table Top -->
        <b-row class="text-center">
          <!-- Search -->

          <b-col>
                  <b-button v-b-modal.modal-login variant="outline-primary">
              <feather-icon icon="FilterIcon" class="mr-50" />
              <span class="text-nowrap">Filters</span>
            </b-button>
          </b-col>

          <b-col cols="7">
            <b-form-input
              v-model="searchQuery"
              class="d-inline-block"
              placeholder="Search..."
            />
          </b-col>

          <b-col>
            <b-form-radio-group
              v-model="itemView"
              class="list item-view-radio-group"
              buttons
              size="sm"
              button-variant="outline-primary"
            >
              <b-form-radio
                v-for="option in itemViewOptions"
                :key="option.value"
                :value="option.value"
              >
                <feather-icon :icon="option.icon" size="20" />
              </b-form-radio>
            </b-form-radio-group>
          </b-col>

          <!-- Per Page -->
        </b-row>
      </div>
    
     
      <div class="mt-2" v-if="itemView == 'list-view'">
        <b-card>

          <b-table
            :items="filterProducts"
            :fields="columns"
            responsive
            striped
          >
            <template #cell(business)="data">
              <span style="color: #f88379">
                <strong> {{ data.item.business }} </strong>
              </span>
            </template>
            <template #cell(cover)="data">
              <b-img
                :src="data.value"
                style="width: 75px; height: 75px"
                rounded
                alt="Rounded image"
              />
            </template>
            <template #cell(name)="data">
              <span>
                <strong> {{ data.item.name }} </strong>
              </span>
            </template>
            <template #cell(charges)="data">
              <span> AED {{ data.item.charges }} </span>
            </template>
 <template #cell(status)="data">
      <b-badge
                      v-if="data.item.status == 'Approved'"
                      variant="success"
                      class="badge-glow"
                    >
                      Approved
                    </b-badge>

                    <b-badge
                      v-if="data.item.status  == 'Pending'"
                      variant="secondary"
                      class="badge-glow"
                    >
                      Pending
                    </b-badge>
                    <b-badge
                      v-if="data.item.status  == 'Suspended'"
                      variant="warning"
                      class="badge-glow"
                    >
                      Suspended
                    </b-badge>
                    <b-badge
                      v-if="data.item.status  == 'Rejected'"
                      variant="danger"
                      class="badge-glow"
                    >
                      Rejected
                    </b-badge>


            </template>

                          <template #cell(actions)="data">
              <b-button
                variant="outline-secondary"
                class="btn-wishlist mr-0 mr-sm-1 mb-1 mb-sm-0"
                @click="Details(data.index)"
              >
                <feather-icon icon="FileTextIcon" class="mr-50" />
                <span>Details</span>
              </b-button>
              <!-- <b-button
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                variant="primary"
                class="btn-cart mr-0 mr-sm-1 mb-1 mb-sm-0"
               v-b-modal.add-product @click="EditProduct(data.item)"
              >
                <feather-icon
                  icon="EditIcon"
                  class="mr-50"
                />
                
              </b-button> -->
              <!-- <b-button
      v-ripple.400="'rgba(113, 102, 240, 0.15)'"
      variant="outline-primary"
      class="btn-icon"
      @click="DeleteProduct(data.item.productId)"
    >
      <feather-icon icon="XIcon" />
    </b-button> -->
            </template>
            <!-- <template #cell(status)="data">
        <b-badge :variant="status[1][data.value]">
          {{ status[0][data.value] }}
        </b-badge>
      </template> -->
          </b-table>
          <b-row v-if="filterProducts.length == 0">
            <b-col cols="12" class="demo-spacing-0">
              <b-alert variant="primary" show>
                <div class="alert-body">
                  <span><strong>Empty!</strong> No service found..</span>
                </div>
              </b-alert>
            </b-col>
          </b-row>
        </b-card>
      </div>

      <div v-if="productEmpty == true">
        <b-alert show variant="secondary" class="p-2">No Data</b-alert>
      </div>
      <b-row class="mt-2" v-if="itemView == 'grid-view'">
        <b-col
          v-for="(product, index) in filterProducts"
          v-bind:key="product.id"
          xl="3"
          lg="3"
          cols="12"
          md="4"
          sm="6"
        >
          <!--<b-card-group deck v-for="p in myObj" :key="p.name">-->
          <a v-b-modal.add-product @click="Details(index)">
            <b-card
              :img-src="product.cover"
              img-top
              img-alt="card img"
              img-height="200px"
              border-variant="light"
              v-if="productEmpty == false"
            >
              <b-row style="margin-top: 5px">
                <b-col md="12">
                  <b-card-text style="color: #f88379">
                    <b> {{ product.business }} </b>
                  </b-card-text>
                </b-col>
              </b-row>
              <b-row style="margin-top: 5px">
                <b-col md="12">
                  <b-card-text>
                    <b> {{ product.name }} </b>
                  </b-card-text>
                </b-col>
              </b-row>

              <b-row style="margin-top: 5px">
                <b-col md="12">
                  <b-card-text>
                    {{ product.category }} -> {{ product.subcategory }}
                  </b-card-text>
                </b-col>
              </b-row>
              <b-row style="margin-top: 5px">
                <b-col md="9">
                  <b-card-text> AED {{ product.charges }} </b-card-text>
                </b-col>
              </b-row>
              <b-row style="margin-top: 5px">
                <b-col md="9">
                  <b-card-text>
                    <b-badge
                      v-if="product.status == 'Approved'"
                      variant="success"
                      class="badge-glow"
                    >
                      Approved
                    </b-badge>

                    <b-badge
                      v-if="product.status == 'Pending'"
                      variant="secondary"
                      class="badge-glow"
                    >
                      Pending
                    </b-badge>
                    <b-badge
                      v-if="product.status == 'Suspended'"
                      variant="warning"
                      class="badge-glow"
                    >
                      Suspended
                    </b-badge>
                    <b-badge
                      v-if="product.status == 'Denied'"
                      variant="danger"
                      class="badge-glow"
                    >
                      Denied
                    </b-badge>
                  </b-card-text>
                </b-col>
              </b-row>
            </b-card>
          </a>

          <!--</b-card-group>-->
        </b-col>
        <b-col
          v-if="filterProducts.length == 0"
          cols="12"
          class="demo-spacing-0"
        >
          <b-alert variant="primary" show>
            <div class="alert-body">
              <span><strong>Empty!</strong> No service found..</span>
            </div>
          </b-alert>
        </b-col>
      </b-row>

      <!-- Pagination -->
      <b-col
        cols="12"
        sm="6"
        class="
          d-flex
          align-items-center
          justify-content-center justify-content-sm-end
        "
      >
        <!-- <b-pagination
            v-model="currentPage"
            :total-rows="totalOrders"
            :per-page="perPage"
            first-number
            last-number
            class="mb-0 mt-1 mt-sm-0"
            prev-class="prev-item"
            next-class="next-item"
          >
            <template #prev-text>
              <feather-icon
                icon="ChevronLeftIcon"
                size="18"
              />
            </template>
            <template #next-text>
              <feather-icon
                icon="ChevronRightIcon"
                size="18"
              />
            </template>
          </b-pagination> -->
      </b-col>
    </div>
  </div>
</template>

<script>
import {
  BRow,
  BCol,
  BForm,
  BCard,
  BCardBody,
  BFormInput,
  BFormGroup,
  BFormCheckbox,
  BCardText,
  BCardTitle,
  BFormTextarea,
  BMedia,
  BMediaAside,
  BButton,
  BPagination,
  BDropdown,
  BDropdownItem,
  BAlert,
  BSpinner,
  BCardImg,
  BImg,
  BFormRadioGroup,
  BFormRadio,
  BAvatar,
  BTable,
  BLink,
  BBadge,
} from 'bootstrap-vue';
import vSelect from 'vue-select';
import { ref, onUnmounted } from '@vue/composition-api';
import { avatarText } from '@core/utils/filter';
import axios from '@axios';
import { BFormSelect } from 'bootstrap-vue';
import VueGallery from 'vue-gallery';
import flatPickr from 'vue-flatpickr-component';
import 'flatpickr/dist/flatpickr.css';
export default {
  props: ['title'],
  components: {
    // flatPickr,
    vSelect,
    BTable,
    BBadge,
    //VueGoodTable,
    //BAvatar,
    BForm,
    //BFormTextarea,
    //BFormCheckbox,
    BFormInput,
    BCard,
    BCardText,
    BRow,
    BFormGroup,
    //BMedia,
    BSpinner,
    BImg,
    //BLink,
    //BMediaAside,
    //BCardBody,
    BFormRadioGroup,
    BFormRadio,
    BCol,
    BButton,
    // BPagination,
    //BDropdown,
    //BDropdownItem,
    BCardImg,
    //BFormSelect,
    BAlert,
    BSpinner,
    gallery: VueGallery,
  },

  created() {
    this.LoadData();
  },
  computed: {
    filterProducts: function () {
      return this.myProducts.filter((pro) => {
        return pro.name.toLowerCase().match(this.searchQuery.toLowerCase());
      });
    },
  },
  mounted() {},
  data() {
    return {
      token:
        'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJzdWIiOiJPc2FtYSIsImVtYWlsIjoib3NhbWF1c21hbjU1NUBnbWFpbC5jb20iLCJqdGkiOiJjMzI0MGI0ZS1mM2NlLTQxZGUtYmRmNS1iYTE1YTFjMjkyZDIiLCJleHAiOjE2NzA1NjU2MjQsImlzcyI6ImFwcGlja3NvbHV0aW9ucy5pby5jb20iLCJhdWQiOiJhcHBpY2tzb2x1dGlvbnMuaW8uY29tIn0.0_pK3DJcXTTEAk-fFziiMOkgGUC7nUYXppJ9C7dOIic',
      mycategoryId: 0,
      dataloading: false,
      plus: '',
      moreloading: false,
      logoloading: false,
      mysubCategories: [],
      categories: [],
      subCategories: [],
      deleteObj: {
        productIds: [],
        isDeleted: '',
      },
      statusObj: {
        productIds: [],
        status: '',
      },

      selected: [],
      rows: [],
      columns: [
        {
          label: '',
          key: 'cover',
        },
        {
          label: 'Vendor',
          key: 'business',
        },
        {
          label: 'Name',
          key: 'name',
        },

        {
          label: 'Charges',
          key: 'charges',
        },
          {
          label: 'Status',
          key: 'status',
        },
        {
          label: '',
          key: 'actions',
        },
      ],
      itemViewOptions: [
        { icon: 'GridIcon', value: 'grid-view' },
        { icon: 'ListIcon', value: 'list-view' },
      ],
      itemView: 'list-view',
      searchQuery: '',
      loading: false,
      filterData: '',
      date: {
        range: '',
      },
      detailObj: {},
      index: null,
      rangeDate: null,
       productfilterOptions: ['Pending', 'Approved', 'Suspended','All'],
      cakeLayer: [],
      myProducts: [],
      productEmpty: false,
      myObj: {
        id: 0,
        name: '',
        images: [],
        forHome: false,
        categoryId: 0,
        charges: 0,
        serviceCategoryId: 0,
        subcategoryId: 0,
        vendorId: '',
      },
      cakeImages: [],
      flavour: [],
      cakeSize: [],
    };
  },
  methods: {
    ServiceStatus(id, status) {
      Swal.fire({
        title: 'Do you want to ' + status + ' this service ?',
        text: "You won't be able to revert this!",
        icon: 'question',
        iconColor: '#FB595f',
        confirmButtonText: 'Yes',
        confirmButtonColor: '#FB595f',
      }).then((result) => {
        /* Read more about isConfirmed, isDenied below */
        if (result.isConfirmed) {
          var axios = require('axios');
          var config = {
            method: 'put',
            url:
              'https://brandaway.fastech.pk/api/Services/ServiceStatus/' +
              id +
              '/' +
              status,
            headers: {
              Authorization: 'Bearer ' + this.token,
            },
          };
          console.log(config.url);
          axios(config)
            .then((response) => {
              console.log(JSON.stringify(response.data));
              if (response.data.status === 'success') {
                  this.$bvModal.hide('modal-select2');
                Swal.fire(status + '!', '', 'success').then((res) => {
                  this.LoadData();
                });
              }
            })
            .catch(function (error) {
              console.log(error);
            });
        }
      });
    },
    Details(index) {
      this.myindex = index;
      console.log(this.detailObj);
      console.log(index);
      this.detailObj = this.myProducts[index];
      this.detailObj.images = this.detailObj.images.toString();
      console.log(this.detailObj.images);
      this.detailObj.images = this.detailObj.images.split(',');

      //    if(this.detailObj.images.length !== 0)
      //    {
      //     //return 0;
      //     console.log("If Working");
      //    }
      //    else
      //    {
      //    }
      //this.detailObj.images = "";
      this.$bvModal.show('modal-select2');
    },
    EmptyForm() {
      this.myObj.id = 0;
      this.myObj.name = '';
      this.myObj.images = [];
      this.myObj.charges = 0;
      this.myObj.forHome = false;
      this.myObj.description = '';
      this.myObj.categoryId = 0;
      this.myObj.subcategoryId = 0;
      this.myObj.serviceCategoryId = 0;
      this.myObj.vendorId = 0;
    },
    EditProduct(row) {
      this.myObj.id = row.id;
      this.myObj.name = row.name;
      this.myObj.images = row.images.split(',');
      this.myObj.charges = row.charges;
      this.myObj.forHome = row.forHome;
      this.myObj.description = row.description;
      this.myObj.categoryId = row.categoryId;
      this.myObj.subcategoryId = row.subcategoryId;
      this.myObj.serviceCategoryId = row.serviceCategoryId;
      this.myObj.vendorId = row.vendorId;
    },
    checkCategory() {
      if (this.myObj.categoryId == 0 || this.myObj.categoryId == null) {
        return (this.errors.category = true);
      } else {
        return (this.errors.category = false);
      }
    },

    AddProduct() {
      this.myObj.charges = parseInt(this.myObj.charges);
      this.myObj.images = this.myObj.images.toString();
      this.myObj.vendorId = this.$store.state.userData.userID;
      this.myObj.categoryId = this.mycategoryId;
      //return console.log(this.myObj);

      if (this.myObj.id == 0) {
        console.log(this.myObj);
        var axios = require('axios');

        var config = {
          method: 'post',
          url: 'https://brandaway.fastech.pk/api/Services',
          headers: {
            Authorization: 'Bearer ' + this.$store.state.userData.token,
            'Content-Type': 'application/json',
          },
          data: this.myObj,
        };
        axios(config)
          .then((response) => {
            //console.log(response.data);
            if (response.data.status == 'success') {
              this.LoadData();
              this.$bvToast.toast('Service Added.', {
                title: 'Success!',
                variant: 'success',
                toaster: 'b-toaster-bottom-center',
              });
            }
          })
          .catch(function (error) {
            console.log(error);
          });
      } else if (this.myObj.id != 0) {
        console.log(this.myObj);
        var axios = require('axios');

        var config = {
          method: 'put',
          url: 'https://brandaway.fastech.pk/api/Services/' + this.myObj.id,
          headers: {
            Authorization: 'Bearer ' + this.$store.state.userData.token,
            'Content-Type': 'application/json',
          },
          data: this.myObj,
        };
        axios(config)
          .then((response) => {
            //console.log(response.data);
            if (response.data.status == 'success') {
              this.LoadData();
              this.$bvToast.toast('Service Updated.', {
                title: 'Success!',
                variant: 'success',
                toaster: 'b-toaster-bottom-center',
              });
            }
          })
          .catch(function (error) {
            console.log(error);
          });
      }
    },

    LoadsubCategory() {
      this.subCategories.forEach((e) => {
        if (e.categoryId == this.myObj.categoryId) {
          this.mysubCategories.push(e);
        }
      });
      console.log(this.mysubCategories);
    },
    rowClass(item, type) {
      const colorClass = 'table-secondary';
      if (!item || type !== 'row') {
        return;
      }

      // eslint-disable-next-line consistent-return
      //console.log("Hello", item);
      if (item.status == 'inactive') {
        return colorClass;
      }
    },
    DeletedAll(deleted) {
      this.deleteObj.productIds = [];
      this.selected.forEach((elem) => {
        this.deleteObj.productIds.push(elem.productId);
      });
      this.deleteObj.isDeleted = deleted;
      Swal.fire({
        title: 'Are you sure?',
        text: "You won't be able to revert this!",
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Yes, Delete it!',
      }).then((result) => {
        if (result.isConfirmed) {
          var axios = require('axios');

          var config = {
            method: 'put',
            url: 'https://okaaik.fastech.pk/api/products/RecentlyDelete',
            headers: {
              Authorization:
                'bearer 56EeZXfkyt2E4yYawlxCo3FFMZiFmhDs2VZbA9jLIfTs70L_z5gC9yAMCchwnNRUcicotXJNc3GcSB8M-T9i8PLd-HUMk4KXom3Uu_kC4kbFhSk02Z9YQRwZqVdXjECBVCA0gOhnVoRLztCFQxNgVJbeWEMubqzh8dFpvnkIoC_2FF0kyCzKbwj4SYtF1fMMyhy2CO8DOIWUTZtdpwpI-IjGB0CyHhav_I_ag72apOUtKKhAoshLdYfcFz3QBYC0Pj-FIyEbzz_S5Rcp9fiIG3y5UM6RtSf8zP08M42rVjkLHwOb5cc8YafdfFExhGFI',
            },
            data: this.deleteObj,
          };

          axios(config)
            .then((response) => {
              console.log(response.data);
              if (response.data.status === 'success') {
                Swal.fire(
                  'Deleted!',
                  'Your products has been deleted.',
                  'success',
                ).then((res) => {
                  this.LoadData();
                  this.$bvModal.hide('modal-select2');
                });
              } else if (response.data.status == 'exist') {
                Swal.fire(
                  'Not Allowed!',
                  'You cannot delete </br>' +
                    " ' " +
                    response.data.data +
                    " ' " +
                    '</br> these has orders.',
                  'warning',
                ).then((res) => {
                  this.LoadData();
                  this.$bvModal.hide('modal-select2');
                });
              } else {
                Swal.fire('Error!', 'Something went wrong.', 'error');
              }

              console.log(response.data);
            })
            .catch(function (error) {
              console.log(error);
            });
        }
      });
    },
    ActiveAll(status) {
      this.statusObj.productIds = [];
      this.selected.forEach((elem) => {
        this.statusObj.productIds.push(elem.productId);
      });
      this.statusObj.status = status;
      ///console.log()
      var axios = require('axios');

      var config = {
        method: 'post',
        url: 'https://okaaik.fastech.pk/api/products/ActiveAll',
        headers: {
          Authorization:
            'bearer 56EeZXfkyt2E4yYawlxCo3FFMZiFmhDs2VZbA9jLIfTs70L_z5gC9yAMCchwnNRUcicotXJNc3GcSB8M-T9i8PLd-HUMk4KXom3Uu_kC4kbFhSk02Z9YQRwZqVdXjECBVCA0gOhnVoRLztCFQxNgVJbeWEMubqzh8dFpvnkIoC_2FF0kyCzKbwj4SYtF1fMMyhy2CO8DOIWUTZtdpwpI-IjGB0CyHhav_I_ag72apOUtKKhAoshLdYfcFz3QBYC0Pj-FIyEbzz_S5Rcp9fiIG3y5UM6RtSf8zP08M42rVjkLHwOb5cc8YafdfFExhGFI',
        },
        data: this.statusObj,
      };

      axios(config)
        .then((response) => {
          if (response.data.status == 'success') {
            this.LoadData();
            this.$bvToast.toast('Status updated.', {
              title: 'Success!',
              variant: 'success',
              toaster: 'b-toaster-bottom-center',
            });
          }
          console.log(response.data.data);
        })
        .catch(function (error) {
          console.log(error);
        });
    },
    onRowSelected(items) {
      this.selected = items;
      console.log(this.selected);
    },
    selectAllRows() {
      this.$refs.selectableTable.selectAllRows();
    },
    clearSelected() {
      this.$refs.selectableTable.clearSelected();
    },
    // AddProduct()
    // {
    //   this.$store.state.userData.productId = 0;
    //   console.log("storeId", this.$store.state.userData.productId);
    //   this.$router.push('/apps/products/add');

    // },
    ProductEdit(id) {
      this.$store.state.userData.productId = id;

      console.log('storeId', this.$store.state.userData.productId);
      this.$router.push('/apps/products/add');
    },
      Filter() {
      // console.log("Osama");
      console.log(this.filterData);
      var axios = require('axios');
      var screen = {
        method: 'get',
        url:
          'https://brandaway.fastech.pk/api/Services/AdminFilter/' + this.filterData,
        headers: {
          Authorization: 'bearer ' + this.$store.state.userData.token,
        },
      };

      axios(screen)
        .then((response) => {
          console.log(response.data);
          this.myProducts = response.data.data;
          console.log(this.myProducts);
          console.log(response.data);
        })
        .catch(function (error) {
          console.log(error);
        });
    },
    ProductDetails(id) {
      this.loading = true;
      this.cakeImages = [];
      this.cakeLayers = [];
      this.cakeSize = [];
      this.flavour = [];
      console.log(id);
      var axios = require('axios');

      var config = {
        method: 'get',
        url: 'https://okaaik.fastech.pk/api/products/GetproductbyId/' + id,
        headers: {
          Authorization:
            'bearer 56EeZXfkyt2E4yYawlxCo3FFMZiFmhDs2VZbA9jLIfTs70L_z5gC9yAMCchwnNRUcicotXJNc3GcSB8M-T9i8PLd-HUMk4KXom3Uu_kC4kbFhSk02Z9YQRwZqVdXjECBVCA0gOhnVoRLztCFQxNgVJbeWEMubqzh8dFpvnkIoC_2FF0kyCzKbwj4SYtF1fMMyhy2CO8DOIWUTZtdpwpI-IjGB0CyHhav_I_ag72apOUtKKhAoshLdYfcFz3QBYC0Pj-FIyEbzz_S5Rcp9fiIG3y5UM6RtSf8zP08M42rVjkLHwOb5cc8YafdfFExhGFI',
        },
      };

      axios(config)
        .then((response) => {
          //this.cakeImages.push(response.data.images);
          console.log(response.data);
          this.cakeImages = [];
          response.data.images.forEach((elem) => {
            this.cakeImages.push(elem.imagePath);
          });
          this.cakeSize.push(response.data.cakesize);
          this.flavour.push(response.data.flavours);
          this.detailObj = response.data.data[0];
          this.cakeLayers = response.data.cakelayer;
          console.log('Mylayers', this.cakeLayers);
          console.log(this.detailObj);
          console.log(this.cakeImages);
          console.log(this.cakeSize[0]);

          console.log(this.flavour[0]);
          this.loading = false;
          //console.log( response.data);
        })
        .catch(function (error) {
          console.log(error);
        });
    },
    DeleteProduct(id) {
      this.deleteObj.productIds = [];
      this.deleteObj.productIds.push(id);
      this.deleteObj.isDeleted = true;
      /// return console.log(this.deleteObj);
      Swal.fire({
        title: 'Are you sure?',
        text: "You won't be able to revert this!",
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Yes, Delete it!',
      }).then((result) => {
        if (result.isConfirmed) {
          var axios = require('axios');

          var config = {
            method: 'put',
            url: 'https://okaaik.fastech.pk/api/products/RecentlyDelete',
            headers: {
              Authorization:
                'bearer 56EeZXfkyt2E4yYawlxCo3FFMZiFmhDs2VZbA9jLIfTs70L_z5gC9yAMCchwnNRUcicotXJNc3GcSB8M-T9i8PLd-HUMk4KXom3Uu_kC4kbFhSk02Z9YQRwZqVdXjECBVCA0gOhnVoRLztCFQxNgVJbeWEMubqzh8dFpvnkIoC_2FF0kyCzKbwj4SYtF1fMMyhy2CO8DOIWUTZtdpwpI-IjGB0CyHhav_I_ag72apOUtKKhAoshLdYfcFz3QBYC0Pj-FIyEbzz_S5Rcp9fiIG3y5UM6RtSf8zP08M42rVjkLHwOb5cc8YafdfFExhGFI',
            },
            data: this.deleteObj,
          };

          axios(config)
            .then((response) => {
              console.log(response.data);
              if (response.data.status === 'success') {
                Swal.fire(
                  'Deleted!',
                  'Your product has been deleted.',
                  'success',
                ).then((res) => {
                  this.LoadData();
                  this.$bvModal.hide('modal-select2');
                });
              } else if (response.data.status == 'exist') {
                Swal.fire(
                  'Not Allowed!',
                  'You cannot delete </br>' +
                    " ' " +
                    response.data.data +
                    " ' " +
                    '</br> it has orders.',
                  'warning',
                ).then((res) => {
                  this.LoadData();
                  this.$bvModal.hide('modal-select2');
                });
              } else {
                Swal.fire('Error!', 'Something went wrong.', 'error');
              }

              console.log(response.data);
            })
            .catch(function (error) {
              console.log(error);
            });
        }
      });
    },

    removeImg(index) {
      console.log(index);
      this.myObj.images.splice(index, 1);
    },

    selectMoreFile() {
      //this.moreFiles = this.$refs.mfile.files;
      if (this.$refs.mfile.files.length !== 0) {
        this.moreloading = true;
        var formdata = new FormData();
        for (var i = 0; i < this.$refs.mfile.files.length; i++) {
          formdata.append('file', this.$refs.mfile.files[i]);
        }

        var requestOptions = {
          method: 'POST',
          body: formdata,
          redirect: 'follow',
        };

        fetch('https://upload.appick.io', requestOptions)
          .then((response) => response.json()) //2
          .then((result) => {
            console.log();
            //this.moreImages.push(result.myresp);

            for (var i = 0, len = result.myresp.length; i < len; ++i) {
              this.moreloading = false;
              var fn = result.myresp[i].path;
              this.myObj.images.push(fn);
            }
            console.log(this.myObj.images);
          })

          .catch((error) => console.log('error', error));
      }
    },
    LoadData() {
      var axios = require('axios');

      var screen = {
        method: 'get',
        url: 'https://brandaway.fastech.pk/api/Services/ServiceForAdmin/',
        headers: {
          Authorization: 'bearer ' + this.$store.state.userData.token,
        },
      };

      axios(screen)
        .then((response) => {
          console.log(response.data);

          //  this.data  =  response.data;
          console.log('Helloooo', response.data);
          this.myProducts = response.data.services;
          // this.mycategoryId = response.data.categoryId[0];
          // this.categories = response.data.subCategories;
          // this.subCategories = response.data.serviceCategories;

          //  this.rows = response.data.data;

          console.log('HelloService', this.myProducts);
          console.log(response.data);
        })
        .catch(function (error) {
          console.log(error);
        });
    },
  },
};
</script>

<style lang="scss" scoped>
.per-page-selector {
  width: 90px;
}
</style>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>

<style lang="scss">
@import '~@core/scss/base/pages/app-ecommerce-details.scss';
</style>
<style lang="scss">
@import '~@core/scss/base/pages/app-ecommerce.scss';
</style>
<style scoped>
.image {
  float: left;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
  border: 1px solid #ebebeb;
  border-radius: 5px;
  margin: 5px;
}
</style>

